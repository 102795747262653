import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { CSSProperties, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { PAGINATION_SIZE } from '@/constants/common';
import { toRem } from '@/utils/commonUtils';

export interface InfiniteScrollConfig<T> {
  isLoading: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<T>>;
  hasNextPage: boolean | undefined;
}

export interface InjectedItemProps<I> {
  index: number;
  data: I[];
  render: (data: I[]) => ReactElement[];
}

interface ContentWrapperProps {
  isGrid?: boolean;
  repeat?: number;
  padding?: {
    top?: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;
  };
  backgroundColor?: string;
}

interface InfiniteScrollProps<D> extends ContentWrapperProps {
  data: D[] | undefined;
  renderItems: (data: D, index: number) => ReactElement;
  onFetchNext: () => void;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
  paginationSize?: number;
  skeleton: ReactElement;
  skeletonCount?: number;
  injectedItems?: InjectedItemProps<any>[];
  renderEmptyComponent?: ReactElement;
  className?: string;
  style?: CSSProperties;
}

const InfiniteScroll = <D,>({
  data,
  renderItems,
  onFetchNext,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  skeleton,
  skeletonCount = 10,
  paginationSize = PAGINATION_SIZE,
  injectedItems,
  renderEmptyComponent,
  isGrid = false,
  padding,
  backgroundColor,
  className,
  repeat,
  style,
}: InfiniteScrollProps<D>) => {
  const ref = useRef<HTMLDivElement>(null);

  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      if (data) {
        if (!isLoading && data.length >= paginationSize && hasNextPage && !isFetchingNextPage && entry.isIntersecting) {
          onFetchNext();
        }
      }
    },
    [data, hasNextPage, isFetchingNextPage, isLoading, onFetchNext, paginationSize],
  );

  useEffect(() => {
    let observer: IntersectionObserver;
    if (ref.current) {
      observer = new IntersectionObserver(observerCallback, { rootMargin: '5px', threshold: 0 });
      observer.observe(ref.current);
    }
    return () => observer?.disconnect();
  }, [observerCallback]);

  const handleReturnSkeletons = useCallback(() => {
    const arr = [];
    for (let i = 0; i < skeletonCount; i += 1) {
      arr.push(<React.Fragment key={`${skeleton}-${i}`}>{skeleton}</React.Fragment>);
    }
    return arr;
  }, [skeleton, skeletonCount]);

  const skeletons = useMemo(() => handleReturnSkeletons(), [handleReturnSkeletons]);

  const Content = (): any => {
    if (isLoading) {
      return skeletons;
    }
    if (!data || data.length === 0) {
      return renderEmptyComponent;
    }
    return data.map((item: D, index: number) => (
      <React.Fragment key={`${item}-${index}`}>
        {injectedItems &&
          injectedItems.map((inject: InjectedItemProps<any>) =>
            inject.index === index ? inject.render(inject.data) : null,
          )}
        {renderItems(item, index)}
      </React.Fragment>
    ));
  };

  return (
    <Wrapper style={style} className={className}>
      <ContentWrapper isGrid={isGrid} padding={padding} backgroundColor={backgroundColor} repeat={repeat}>
        <Content />
        {skeleton && isFetchingNextPage && skeletons}
      </ContentWrapper>
      <span ref={ref} />
    </Wrapper>
  );
};

export default InfiniteScroll;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DEFAULT_CONTENT_WRAPPER_STYLE = css`
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 ${toRem(16)};
`;

const GRID_CONTENT_WRAPPER_STYLE = (repeat: number) => css`
  display: grid;
  grid-template-columns: repeat(${repeat}, 1fr);
  gap: 8px;
  padding: 0;
`;

export const ContentWrapper = styled.div<ContentWrapperProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ isGrid, repeat = 3 }) =>
    isGrid
      ? {
          ...GRID_CONTENT_WRAPPER_STYLE(repeat),
        }
      : DEFAULT_CONTENT_WRAPPER_STYLE}
  ${({ padding = { top: 0, left: toRem(16), bottom: 0, right: toRem(16) } }) =>
    css`
      padding-top: ${padding.top};
      padding-left: ${padding.left};
      padding-right: ${padding.right};
      padding-bottom: ${padding.bottom};
    `}
`;
