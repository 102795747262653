import { useCallback } from 'react';
import useEventTrackingStore from '@/store/useEventTrackingStore';
import { getPlatform } from '@/utils/deviceUtils';
import { Service } from '@/utils/tracker/constants';
import { HospitalEvent, HospitalEventProperties } from '@/utils/tracker/event/hospital';
import { getPathPageForTracker } from '@/utils/tracker/trackerUtils';

interface TrackingViewHospitalReservationCurationItemParams {
  curationTotalNames: string[];
  curationTotalCount: number;
}

interface TrackingClickHospitalReservationCurationItemParams {
  listIndex: number;
  curationTotalNames: string[];
  curationTotalCount: number;
  curationName: string;
}

interface TrackingViewHospitalReservationCurationListParams {
  curationName: string;
  tabName: string;
  chipName: string;
  isDefault: boolean;
  isNoResult: boolean;
  isOpenChecked: boolean;
  isReservationChecked: boolean;
  sortBy: string;
  pathPage: string | string[] | undefined;
}

interface TrackingClickHospitalReservationCurationListHospitalParams {
  locationId: number;
  locationName: string;
  distance: number;
  tagNames: string[];
  hospitalReviewTotalCount: number;
  hospitalReviewAvgScore: number;
  labelNames: string[];
  bannerText: string;
  curationName: string;
  tabName: string;
  chipName: string;
  isOpenChecked: boolean;
  isReservationChecked: boolean;
  sortBy: string;
  pathPage: string | string[] | undefined;
}

interface TrackingViewHospitalScrapListParams {
  tabName: string;
  pathPage: string | string[] | undefined;
}

interface TrackingViewHospitalReservationRecentListParams {
  tabName: string;
  pathPage: string | string[] | undefined;
}

const useEventTrackerHospitalCuration = () => {
  const { tracker } = useEventTrackingStore();

  // 병원탭에서 큐레이션 아이템 조회 시
  const trackingViewHospitalReservationCurationItem = useCallback(
    ({ curationTotalNames, curationTotalCount }: TrackingViewHospitalReservationCurationItemParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALRESERVATION_CURATIONITEM, {
        [HospitalEventProperties.CURATION_TOTAL_NAMES]: curationTotalNames,
        [HospitalEventProperties.CURATION_TOTAL_COUNT]: curationTotalCount,
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
      });
    },
    [tracker],
  );

  // 병원탭에서 큐레이션 아이템 세부 항목 클릭 시
  const trackingClickHospitalReservationCurationItem = useCallback(
    ({
      listIndex,
      curationTotalNames,
      curationTotalCount,
      curationName,
    }: TrackingClickHospitalReservationCurationItemParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALRESERVATION_CURATIONITEM, {
        [HospitalEventProperties.LIST_INDEX]: listIndex,
        [HospitalEventProperties.CURATION_TOTAL_NAMES]: curationTotalNames,
        [HospitalEventProperties.CURATION_TOTAL_COUNT]: curationTotalCount,
        [HospitalEventProperties.CURATION_NAME]: curationName,
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
      });
    },
    [tracker],
  );

  // 큐레이션 리스트 페이지 조회 시
  const trackingViewHospitalReservationCurationList = useCallback(
    ({
      curationName,
      tabName,
      chipName,
      isDefault,
      isNoResult,
      isOpenChecked,
      isReservationChecked,
      sortBy,
      pathPage,
    }: TrackingViewHospitalReservationCurationListParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALRESERVATION_CURATIONLIST, {
        [HospitalEventProperties.CURATION_NAME]: curationName,
        [HospitalEventProperties.TAB_NAME]: tabName,
        [HospitalEventProperties.CHIP_NAME]: chipName,
        [HospitalEventProperties.IS_DEFAULT]: isDefault,
        [HospitalEventProperties.IS_NO_RESULT]: isNoResult,
        [HospitalEventProperties.IS_OPEN_CHECKED]: isOpenChecked,
        [HospitalEventProperties.IS_RESERVATION_CHECKED]: isReservationChecked,
        [HospitalEventProperties.SORT_BY]: sortBy,
        [HospitalEventProperties.PATH_PAGE]: getPathPageForTracker({ pathname: pathPage }),
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
      });
    },
    [tracker],
  );

  // 큐레이션 리스트 페이지에서 병원 클릭 시
  const trackingClickHospitalReservationCurationListHospital = useCallback(
    ({
      locationId,
      locationName,
      distance,
      tagNames,
      hospitalReviewTotalCount,
      hospitalReviewAvgScore,
      labelNames,
      bannerText,
      curationName,
      tabName,
      chipName,
      isOpenChecked,
      isReservationChecked,
      sortBy,
      pathPage,
    }: TrackingClickHospitalReservationCurationListHospitalParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALRESERVATION_CURATIONLIST_HOSPITAL, {
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.DISTANCE]: distance,
        [HospitalEventProperties.TAG_NAMES]: tagNames,
        [HospitalEventProperties.HOSPITAL_REVIEW_TOTAL_COUNT]: hospitalReviewTotalCount,
        [HospitalEventProperties.HOSPITAL_REVIEW_AVG_SCORE]: hospitalReviewAvgScore,
        [HospitalEventProperties.LABEL_NAMES]: labelNames,
        [HospitalEventProperties.BANNER_TEXT]: bannerText,
        [HospitalEventProperties.CURATION_NAME]: curationName,
        [HospitalEventProperties.TAB_NAME]: tabName,
        [HospitalEventProperties.CHIP_NAME]: chipName,
        [HospitalEventProperties.IS_OPEN_CHECKED]: isOpenChecked,
        [HospitalEventProperties.IS_RESERVATION_CHECKED]: isReservationChecked,
        [HospitalEventProperties.SORT_BY]: sortBy,
        [HospitalEventProperties.PATH_PAGE]: getPathPageForTracker({ pathname: pathPage }),
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
      });
    },
    [tracker],
  );

  // 최근 방문한 병원 리스트 페이지 조회 시
  const trackingViewHospitalReservationRecentList = useCallback(
    ({ tabName, pathPage }: TrackingViewHospitalReservationRecentListParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALRESERVATION_RECENTLIST, {
        [HospitalEventProperties.TAB_NAME]: tabName,
        [HospitalEventProperties.PATH_PAGE]: getPathPageForTracker({ pathname: pathPage }),
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
      });
    },
    [tracker],
  );

  // 스크랩 리스트 페이지 조회 시
  const trackingViewHospitalScrapList = useCallback(
    ({ tabName, pathPage }: TrackingViewHospitalScrapListParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITAL_SCRAPLIST, {
        [HospitalEventProperties.TAB_NAME]: tabName,
        [HospitalEventProperties.PATH_PAGE]: getPathPageForTracker({ pathname: pathPage }),
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
      });
    },
    [tracker],
  );

  return {
    trackingViewHospitalReservationCurationItem,
    trackingClickHospitalReservationCurationItem,
    trackingViewHospitalReservationCurationList,
    trackingClickHospitalReservationCurationListHospital,
    trackingViewHospitalReservationRecentList,
    trackingViewHospitalScrapList,
  };
};

export default useEventTrackerHospitalCuration;
