import styled from '@emotion/styled';
import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import FlatIcon from '@/components/common/FlatIcon';
import useFloatingStore from '@/components/floating/useFloatingStore';
import { GLOBAL_COLOR } from '@/styles/colors';
import { GLOBAL_LAYOUT_PX } from '@/styles/layout';
import { Z_INDEX } from '@/styles/zIndex';
import { toRem } from '@/utils/commonUtils';

interface TopButtonProps {
  className?: string;
}

const TopButton = ({ className }: TopButtonProps) => {
  const [isShowing, setIsShowing] = useState(false);
  const { floatingList } = useFloatingStore((state) => state);

  const handleOnTopClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScroll = useMemo(
    () =>
      throttle(() => {
        const maxScrollValue = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const isShowTopButton = window.scrollY > 100 && maxScrollValue > 100;

        if (isShowTopButton) {
          setIsShowing(true);
        } else {
          setIsShowing(false);
        }
      }, 150),
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <Wrapper
      className={className}
      onClick={handleOnTopClick}
      isShowing={isShowing}
      isFloatingExists={floatingList.length > 0}>
      <FlatIcon iconType={'icTop'} size={16} color={GLOBAL_COLOR.GRAY_300} />
    </Wrapper>
  );
};

export default TopButton;

const Wrapper = styled.div<{ isShowing: boolean; isFloatingExists: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${toRem(42)};
  height: ${toRem(42)};
  border: ${toRem(1)} solid ${GLOBAL_COLOR.GRAY_100};
  border-radius: ${toRem(50)};
  background-color: ${GLOBAL_COLOR.WHITE};
  z-index: ${Z_INDEX.FLOAT_BUTTON};
  position: fixed;
  bottom: ${({ isFloatingExists }) => (isFloatingExists ? toRem(76) : toRem(60))};
  right: ${toRem(16)};
  opacity: ${({ isShowing }) => (isShowing ? '90%' : '0%')};
  transform: ${({ isShowing }) => (isShowing ? `scale(1)` : `scale(0)`)};
  transition: opacity 0.2s ease-in-out 0s, transform 0.2s ease-in-out 0s;
  box-shadow: ${toRem(2)} ${toRem(4)} ${toRem(4)} rgba(0, 0, 0, 0.04);

  @media screen and (min-width: ${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px) {
    right: calc((100vw - ${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px) / 2 + ${toRem(16)});
  }
`;
