import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ImageContent from '@/components/common/ImageContent';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

const HospitalCardSkeleton = () => {
  return (
    <Container>
      <InfoContainer>
        <InfoTextContainer>
          <NameSkeleton />
          <TimeSkeleton />
          <InfoSkeleton />
          <ReviewSkeleton />
        </InfoTextContainer>
        <ImageSkeletonWrapper>
          <DefaultImageWrapper>
            <ImageContent />
          </DefaultImageWrapper>
          <Skeleton className={'imageSkeleton'} />
        </ImageSkeletonWrapper>
      </InfoContainer>
      <BadgeSkeleton inline={true} />
      <BadgeSkeleton inline={true} />
      <BadgeSkeleton inline={true} />
    </Container>
  );
};

export default HospitalCardSkeleton;

const Container = styled.div`
  width: 100%;
  padding: ${toRem(18)} 0;
  border-bottom: ${toRem(1)} solid ${GLOBAL_COLOR.GRAY_50};
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${toRem(8)};
`;

const InfoTextContainer = styled.div`
  flex: 1;
`;

const ImageSkeletonWrapper = styled.div`
  width: ${toRem(109)};
  height: ${toRem(109)};
  position: relative;
  display: inline-block;
  margin-left: ${toRem(12)};

  .imageSkeleton {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: ${toRem(109)};
    height: ${toRem(109)};
    border-radius: ${toRem(8)};
    opacity: 0.6;
  }
`;

const DefaultImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${toRem(8)};
  overflow: hidden;
`;

const NameSkeleton = styled(Skeleton)`
  width: 100%;
  height: ${toRem(23)};
  margin-bottom: ${toRem(12)};
`;

const TimeSkeleton = styled(Skeleton)`
  width: 90%;
  height: ${toRem(18)};
`;

const InfoSkeleton = styled(Skeleton)`
  width: 70%;
  height: ${toRem(18)};
`;

const ReviewSkeleton = styled(Skeleton)`
  width: 50%;
  height: ${toRem(18)};
`;

const BadgeSkeleton = styled(Skeleton)`
  width: 12%;
  height: ${toRem(18)};
  border-radius: ${toRem(4)};
  margin-right: ${toRem(4)};
`;
